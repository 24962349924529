import React, {ReactNode, useRef} from 'react'
import {IoMdWarning} from 'react-icons/io'
import {MdCheckCircle} from 'react-icons/md'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'
import useKeyboardEvent from '@hooks/useKeyboardEvent'
import omit from 'lodash/omit'

import Label from '../Label'

export interface Props<T = string> {
  onChange: (value: T) => void
  value?: T
  fieldType?: string
  passProps?: any
  placeholder?: string
  errorMessage?: ReactNode | string
  disabled?: boolean
  label?: ReactNode | string
  description?: ReactNode | string
  onEnter?: () => void
  icon?: ReactNode
  error?: boolean
  success?: boolean
  step?: string
}

export default function Text(props: Props) {
  const inputRef = useRef(null)
  const isDarkMode = useIsDarkMode()

  useKeyboardEvent('Enter', () => {
    if (inputRef.current.focus) {
      props.onEnter && props.onEnter()
    }
  })

  let icon = props.icon

  if (props.error && !icon) {
    icon = <IoMdWarning className="text-red-700" size={24} />
  }

  if (props.success && !icon) {
    icon = <MdCheckCircle className="text-green-700" size={24} />
  }

  return (
    <div className="flex flex-col relative">
      {props.label ? <Label>{props.label}</Label> : null}
      {icon ? <div className="pointer-events-none py-3 pl-3 absolute">{icon}</div> : null}
      <input
        ref={inputRef}
        className={classnames('border rounded-md px-3 py-3 focus:input-v3', {
          'pl-10': icon,
          'bg-zinc-700 border-zinc-700 placeholder:text-gray-400': isDarkMode,
          'border-gray-200 placeholder:text-gray-300': !isDarkMode
        })}
        type={props.fieldType ?? 'text'}
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={event => props.onChange(event.target.value)}
        disabled={props.disabled}
        step={props.step ?? '1'}
        {...omit(props.passProps, ['error', 'success'])}
      />
      <div className="description">{props.description}</div>
      {props.errorMessage && <div className="os-input-error">{props.errorMessage}</div>}
    </div>
  )
}
