import {useState} from 'react'
import {FaRegClock} from 'react-icons/fa'
import {IoMdCalendar} from 'react-icons/io'
import canDeliverNow from '@helpers/websites/canDeliverNow'
import canSchedule from '@helpers/websites/canSchedule'
import useWebsiteId from '@hooks/useWebsiteId'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useTranslation} from 'next-i18next'

import useSchedulePreferences from './useSchedulePreferences.main'

export default function useScheduleOptions() {
  const preferences = useSchedulePreferences()
  const websiteId = useWebsiteId()
  const [deliverNow, setDeliverNow] = useState(preferences.time === 'now')
  const {t} = useTranslation('website', {keyPrefix: 'schedule'})
  const mutate = useMutate()

  const savePreferences = async ({time, dropOffType}: {time: string; dropOffType}) => {
    await mutate({
      mutation: gql`
        mutation setTimePreferences($websiteId: ID, $time: String, $dropOffType: String) {
          setUserPreferences(websiteId: $websiteId, time: $time, dropOffType: $dropOffType) {
            _id
            time
            timeLabel
            dropOffType
            menuId
          }
        }
      `,
      variables: {
        time,
        dropOffType,
        websiteId
      },
      refetchQueries: ['getMyOrderPreferences']
    })
  }

  let error

  if (deliverNow && !canDeliverNow(preferences.store)) {
    error = t('errors.cantDeliverNow')
  }

  if (!deliverNow && !canSchedule(preferences.store)) {
    error = t('errors.cantSchedule')
  }

  const options = [
    {
      label: (
        <>
          <FaRegClock size={14} className="mr-2" />
          {t('asSoonAsPossible')}
        </>
      ),
      value: true
    },
    {
      label: (
        <>
          <IoMdCalendar size={18} className="mr-2" />
          {t('scheduleOrder')}
        </>
      ),
      value: false
    }
  ]

  return {
    deliverNow,
    setDeliverNow,
    options,
    error,
    savePreferences
  }
}
